@import "https://fonts.googleapis.com/css2?family=Roboto&family=Space+Grotesk:wght@400;700&family=Space+Mono:wght@700&display=swap";
body {
  background: #141414;
  margin: 1em 4em;
}

* {
  margin: 0;
  font-family: Space Grotesk;
}

h1 {
  color: #fff;
  margin-bottom: .5em;
  padding: 15px;
  font-family: Space Grotesk;
  font-size: 3em;
}

h2 {
  color: #fff;
  margin-left: 3em;
}

#welcomeSubtitle {
  font-weight: normal;
}

nav {
  height: 4em;
  justify-content: right;
  align-items: center;
  margin: 1em 0;
  display: flex;
}

.buttonBg {
  width: 100px;
  height: 50px;
  z-index: -5;
  background: linear-gradient(90deg, #5029ff 0%, #13111282 66%, #f000 100%);
  display: none;
  position: absolute;
  top: -90px;
  left: 25px;
}

nav div a {
  color: #fff;
  margin: 30px;
  padding: 5px;
  font-family: Space Grotesk;
  font-size: 1.5em;
  text-decoration: none;
  transition: color 50ms;
  display: inline-block;
}

nav div a:hover {
  text-decoration: underline;
}

#intro {
  max-width: 600px;
  background: linear-gradient(90deg, #5029ff 0%, #13111282 66%, #f000 100%);
  border-radius: 10px;
}

section {
  height: 75vh;
  margin-top: 75px;
}

/*# sourceMappingURL=index.54ad5c91.css.map */
