@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Space+Grotesk:wght@400;700&family=Space+Mono:wght@700&display=swap');

body{
    margin: 1em 4em;
    background: rgb(20, 20, 20);
}

*{
    font-family: "Space Grotesk";
    margin: 0;
}

h1{
    font-family: "Space Grotesk";
    color: white;
    padding: 15px;
    font-size: 3em;
    margin-bottom: 0.5em;
}

h2{
    color: rgb(255, 255, 255);
    margin-left: 3em;
}

#welcomeSubtitle{
    font-weight: normal;
}

nav{
    margin: 1em 0em;
    height: 4em;
    align-items: center;
    justify-content: right;
    display: flex;
}

.buttonBg {
    width: 100px;
    height: 50px;
    position: absolute;
    display: none;
    left: 25px;
    top: -90px;
    background: linear-gradient(90deg, rgba(80,41,255,1) 0%, rgba(19, 17, 18, 0.509) 66%, rgba(255,0,0,0) 100%);
    z-index: -5;
}

nav div a{
    display: inline-block;
    font-size: 1.5em;
    margin: 30px;
    padding: 5px;
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-family: "Space Grotesk";
    transition: color 50ms;
}

nav div {
    // background-color: red;
}

nav div a:hover{
    text-decoration: underline;
}

#intro{
    max-width: 600px;
    background: linear-gradient(90deg, rgba(80,41,255,1) 0%, rgba(19, 17, 18, 0.509) 66%, rgba(255,0,0,0) 100%);
    border-radius: 10px;
}

section{
    margin-top: 75px;
    height: 75vh;
}

section:nth-child(2n + 1){
    // background-color: rgb(255, 100, 255);
}